"use client";

import { SearchInput } from "@/shared/components";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRouter, useSearchParams } from "next/navigation";

type Props = {
  searchValue?: string;
  onSearchChange?: (value: string) => void;
  redirect?: boolean;
};

const BannerSearch = ({ onSearchChange, redirect }: Props) => {
  const router = useRouter();
  const searchParams = useSearchParams();

  const iWannaList: string[] = [
    "Learn about",
    "Stay Up To Date on",
    "Research a solution for",
  ];

  const [iAm, setIAm] = useState<string>("");
  const [iWanna, setIWanna] = useState<string>(iWannaList[0]);
  const [iAsk, setIAsk] = useState<string>("");

  useEffect(() => {
    const iamQuery = searchParams.get("iam") || "";
    const iAskQuery = searchParams.get("iask") || "";
    const iwannaQuery = searchParams.get("iwanna") || iWannaList[0];

    setIAm(iamQuery);
    setIAsk(iAskQuery);
    setIWanna(iwannaQuery);

    const combinedSearch = [iamQuery, iwannaQuery, iAskQuery]
      .filter(Boolean)
      .join(" ");

    if (onSearchChange) {
      onSearchChange(combinedSearch);
    }
  }, [searchParams, onSearchChange]);

  const handleCombinedParams = (): URLSearchParams => {
    const params = new URLSearchParams(searchParams.toString());
    if (iAm) {
      params.set("iam", iAm);
    } else {
      params.delete("iam");
    }

    if (iWanna) {
      params.set("iwanna", iWanna);
    } else {
      params.delete("iwanna");
    }

    if (iAsk) {
      params.set("iask", iAsk);
    } else {
      params.delete("iask");
    }

    return params;
  };

  const updateQueryParam = () => {
    const params = handleCombinedParams();

    router.replace(`?${params.toString()}`);
  };

  const onRedirectToSearch = () => {
    const params = handleCombinedParams();

    router.push(`/search?${params.toString()}`);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (redirect) {
        onRedirectToSearch();
      } else {
        updateQueryParam();
      }
    }
  };

  const handleSelect = (value: string) => {
    setIWanna(value);
  };

  return (
    <Box
      display="grid"
      alignItems="center"
      gridTemplateColumns={{ xs: "1fr", lg: "1fr 1fr 2fr" }}
      gap="16px"
    >
      <Stack direction="column" spacing={2}>
        <Typography variant="body1" fontSize={{ xs: "20px" }} fontWeight={700}>
          I am a...
        </Typography>
        <SearchInput
          placeholder="For example IT Director"
          value={iAm}
          onInput={setIAm}
          onKeyDown={handleKeyDown}
        />
      </Stack>

      <Stack direction="column" spacing={2}>
        <Typography variant="body1" fontSize={{ xs: "20px" }} fontWeight={700}>
          I want to
        </Typography>
        <Select
          value={iWanna}
          size="small"
          onChange={(e) => handleSelect(e.target.value)}
          sx={{
            borderRadius: "4px",
            borderColor: "#B5C1DA",
            ".MuiSelect-select": {
              padding: "9px 14px",
            },
          }}
        >
          {iWannaList.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      <Stack direction="column" spacing={2}>
        <Typography variant="body1" fontSize={{ xs: "20px" }} fontWeight={700}>
          Ask anything
        </Typography>
        <SearchInput
          placeholder="e.g. explore companies, find tools, learn..."
          value={iAsk}
          onInput={setIAsk}
          onKeyDown={handleKeyDown}
        />
      </Stack>
    </Box>
  );
};

export default BannerSearch;
