"use client";

import { useEffect, useState, useMemo } from "react";
import debounce from "lodash.debounce";
import { InputBase, InputBaseProps } from "@mui/material";

type Props = {
  value: string;
  timeout?: number;
  onInputDebounce: (value: string) => void;
};

const DebounceInputComponent = ({
  timeout = 500,
  value,
  onInputDebounce,
  ...props
}: Props & InputBaseProps) => {
  const [inputValue, setInputValue] = useState<string>(value);

  const debouncedOnInput = useMemo(
    () =>
      debounce((newValue: string) => {
        onInputDebounce(newValue);
      }, timeout),
    [onInputDebounce, timeout]
  );

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    debouncedOnInput(newValue);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    return () => {
      debouncedOnInput.cancel();
    };
  }, [debouncedOnInput]);

  return <InputBase {...props} value={inputValue} onChange={handleInput} />;
};

export default DebounceInputComponent;
