"use client";
import colors from "@/lib/palette";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import React, { useState } from "react";

export const passwordRegexRule =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{8,}$/;

const passwordRules = [
  { label: "One uppercase character", regex: /[A-Z]/ },
  { label: "One lowercase character", regex: /[a-z]/ },
  { label: "One digit", regex: /\d/ },
  { label: "8 characters minimum", regex: /.{8,}/ },
  { label: "One special symbol", regex: /[!@#$%^&*(),.?":{}|<>]/ },
];

function PasswordInputComponent(
  props: OutlinedInputProps & {
    passwordType?: "primary" | "secondary";
    helperText?: string;
  }
) {
  const { passwordType, helperText, ...rest } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    console.log("showPassword", showPassword);

    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  if (passwordType === "secondary") {
    return (
      <Box position="relative">
        <OutlinedInput
          type={showPassword ? "text" : "password"}
          {...rest}
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label={
                  showPassword ? "hide the password" : "display the password"
                }
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          fullWidth
        />
        {/* {helperText && (
          <Typography
            component={"p"}
            sx={{
              color: "#d32f2f",
              fontWeight: 400,
              fontSize: "12px !important",
              lineHeight: 1.66,
              textAlign: "left",
              marginTop: "3px",
              marginBottom: 0,
              marginLeft: "14px",
              marginRight: "14px",
            }}
          >
            {helperText}
          </Typography>
        )} */}

        {focused && (
          <List
            sx={{
              width: "100%",
              borderRadius: "4px",
              padding: "8px",
              mt: "4px",
              display: "grid",
              gap: "8px",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            {passwordRules.map(({ label, regex }) => (
              <ListItem
                key={label}
                sx={{
                  padding: "0 !important",
                }}
              >
                <Typography
                  sx={{
                    textDecoration: regex.test(props.value as string)
                      ? "line-through"
                      : "none",
                    ...(regex.test(props.value as string) && {
                      color: colors.black700,
                    }),

                    fontSize: {
                      xs: "12px",
                      sm: "14px",
                    },
                    fontWeight: 400,
                  }}
                >
                  • {label}
                </Typography>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <OutlinedInput
        type={showPassword ? "text" : "password"}
        {...rest}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label={
                showPassword ? "hide the password" : "display the password"
              }
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {!showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        fullWidth
      />

      {helperText && (
        <Typography
          component={"p"}
          sx={{
            color: "#d32f2f",
            fontWeight: 400,
            fontSize: "12px !important",
            lineHeight: 1.66,
            textAlign: "left",
            marginTop: "3px",
            marginBottom: 0,
            marginLeft: "14px",
            marginRight: "14px",
          }}
        >
          {helperText}
        </Typography>
      )}
    </Box>
  );
}

export default PasswordInputComponent;
