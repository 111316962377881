import { Box, BoxProps } from "@mui/material";
import React from "react";

type Props = BoxProps & {
  children: React.ReactNode;
};

const IconWrapper = ({ children, ...rest }: Props) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" {...rest}>
      {children}
    </Box>
  );
};

export default IconWrapper;
